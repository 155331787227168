const parseAndTransform = (inputString, char) => {
    let lastChar = "";
    let inTempalte = false;
    let currentString = "";
    let stringArray = [];
    for(let i = 0; i < inputString.length; i++){
        if(lastChar === char && inputString[i] === char){
            let obj;
            if(inTempalte){
                obj = {template: true, string: currentString};
                inTempalte = false;
            }
            else{
                obj = {template: false, string: currentString};
                inTempalte = true;
            }
            if(currentString.length > 0){
                stringArray.push(obj);
            }
            currentString = "";
        }
        else{
            if(inputString[i] !== char){
                currentString += inputString[i];
            }
        }
        lastChar = inputString[i];
    }
    if(currentString.length > 0){
        stringArray.push({template: false, string: currentString})
    }
    return stringArray;
}

export default parseAndTransform;