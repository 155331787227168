import React from "react";

const Section = ({children}) =>{
    return(
        <section className="w-11/12 mx-auto flex justify-center items-center ">
            {children}
        </section>
    )
}

export default Section;