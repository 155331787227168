import React from "react";
import BlurBackground from "./blurBackground";
import Close from "./../../static/images/close_black.svg";

const Modal = ({active, onBackgroundClick}) => {
    if(active){
        return(
            <>
                <BlurBackground
                    onClick={onBackgroundClick}
                    color={"rgba(0,0,0,0.8)"}
                >
                    <div style={{maxWidth: 800}}className="relative mx-auto my-auto bg-white z-50 cursor-auto p-5 rounded-sm overflow-y-scroll">
                        <h3 className="text-lg font-bold">Wie berechnet sich die gesparte Menge an CO<sub>2</sub>?</h3>
                        <p>Wir benutzen für die Berechnung der CO<sub>2</sub>-Ersparnis verschiedene Quellen. Die Emissionen ergeben sich faktisch aus dem geringeren Dateigewicht eines ecoADs verglichen mit einem herkömmlichen Werbebanner. Als Grundlage werden die technischen Spezifikationen des iq digital Portfolios genutzt.</p>
                        <ul className="my-4">
                            <li className="italic">S = Ersparnis an CO<sub>2</sub> in kg im Vergleich zu herkömmlichen Werbebannern lt. technischen Spezifikationen<sup>1</sup></li>
                            <li className="italic">G = Dateigewicht Werbebanner in kB<sup>1</sup></li>
                            <li className="italic">D = Stromverbrauch pro GB Datenübertragung in kWh/GB<sup>2</sup></li>
                            <li className="italic">C = Emittiertes CO<sub>2</sub> pro kWh in g/kWh<sup>3</sup></li>
                        </ul>
                        <p className="italic text-lg">S = ((G<sub>Normal</sub> - G<sub>ecoAD</sub>) / 1.000.000) * Anzahl AdImpressions * D<sub>Stromverbrauch</sub> * C<sub>Deutschland</sub> / 1000</p>
                        <hr style={{margin: "14px 0px", borderColor: "grey"}}/>
                        <ul className="my-2">
                            <li >1: <a className="underline" target="_blank" rel="noreferrer" href="https://techspecs.iqd-ao.de/de/index.php?title=Hauptseite">Technische Spezifikationen iq digital media marketing gmbh</a></li>
                            <li >2: Aslan, Mayers, Koomey, France "Electricity Intensity of Internet Data Transmission: Untangling the Estimates"<span className="italic"><a className="underline" target="_blank" rel="noreferrer" href="https://onlinelibrary.wiley.com/doi/full/10.1111/jiec.12630">Journal of Inudstrial Ecology Volume 22, Issue 4 (2017)</a></span></li>
                            <li >3: Umweltbundesamt <a className="underline" target="_blank" rel="noreferrer" href="https://www.umweltbundesamt.de/presse/pressemitteilungen/bilanz-2019-co2-emissionen-pro-kilowattstunde-strom">"Entwicklung der spezifischen Kohlenstoffdioxid-Emissionen des deutschen Strommix 1990-2018 (2020)</a></li>
                        </ul>
                        <div role="button" className="absolute top-1 right-1 text-black cursor-pointer pt-2" onClick={onBackgroundClick}>
                            <img className="w-5 h-5" src={Close} alt="close modal"></img>
                        </div>
                    </div>
                </BlurBackground>
            </>
        )
    }
    else{
        return(<></>)
    }
}

export default Modal;